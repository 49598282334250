import React from 'react'
import { STATS_URL } from '../../api/environment'

export default function Statistics() {
  return (
    <>
      <iframe
        title="metabase"
        src={STATS_URL}
        frameborder="0"
        width="100%"
        height="1000em"
      ></iframe>
    </>
  )
}