import { axios_auth } from './api-base';
import { API_URL } from './environment';
import * as moment from 'moment'

const TRACK_URL = `${API_URL}working-hours/`


function _formatDate(date) {
  return date ? moment(date).format("YYYY-MM-DD") : null
}

function getURLParams(fromDate, toDate, projects, categories, page) {
  return {
    params: {
      page,
      date_to: _formatDate(toDate),
      date_from: _formatDate(fromDate),
      projects: projects.length > 0 ? projects.join(',') : undefined,
      categories: categories.length > 0 ? categories.join(',') : undefined,
    }
  }
}

function getEntries(page, fromDate = null, toDate = null, projects, categories) {
  const params = getURLParams(fromDate, toDate, projects, categories, page)

  return axios_auth.get(TRACK_URL, params).then(response => response.data).catch(error => console.log(error))
}

function getTotalHoursForUser(fromDate, toDate, projects, categories) {
  const params = getURLParams(fromDate, toDate, projects, categories)

  return axios_auth.get(`${TRACK_URL}total_hours_for_user`, params).then(response => response.data).catch(error => console.log(error))
}

function getAverageHoursForUser(fromDate, toDate, projects, categories) {
  const params = getURLParams(fromDate, toDate, projects, categories)

  return axios_auth.get(`${TRACK_URL}average_hours_for_user`, params).then(response => response.data).catch(error => console.log(error))
}

function deleteEntry(entryId) {
  return axios_auth.delete(`${TRACK_URL}${entryId}`).then(response => response.data).catch(() => null)
}

function saveWorkingHours(workingHourList) {
  return axios_auth.post(`${TRACK_URL}create_many/`, workingHourList).then(response => response.data).catch(() => [])
}

function editWorkingHours(id, workingHourRow) {
  return axios_auth.put(`${TRACK_URL}${id}/`, workingHourRow).then(response => response.data).catch(() => ({}))
}

const service = {
  saveWorkingHours,
  editWorkingHours,
  getEntries,
  getTotalHoursForUser,
  getAverageHoursForUser,
  deleteEntry,
}

export default service