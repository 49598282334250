import React from 'react';
import { Button, useTheme } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors'; 


export default function AcceptCancelButtons({
    accept,
    cancel,
    acceptLabel
}){
  const theme = useTheme()

    return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Button
            variant="contained"
            style={{ backgroundColor: red[500], color: 'white', height: '100%', margin: theme.spacing(1.5) }}
            onClick={cancel}
            data-cy='cancelButton'>
            Cancelar
        </Button>

        <Button
            variant="contained"
            style={{ backgroundColor: green[500], color: 'white', height: '100%', margin: theme.spacing(1.5) }}
            onClick={accept}>
            {acceptLabel}
        </Button>
    </div>
}
