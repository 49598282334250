import React from 'react';
import { Typography, IconButton, useTheme } from '@material-ui/core';
import ReleaseName from '../common/ReleaseName'
import DarkModeButton from '../common/DarkModeButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default function NavBar({
  title,
  onBack
}) {
  const theme = useTheme()

  return <div style={{ display: 'flex', margin: theme.spacing(2.5), flexDirection: 'row', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {onBack && (
        <div style={{ marginRight: theme.spacing(1) }}>
          <IconButton size="small" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      )}
      <Typography
        variant="h5">
        {title}
      </Typography>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <ReleaseName />
      <DarkModeButton />
    </div>
  </div>
}