import { useSnackbar } from "notistack";

export default function useMessages() {
  const { enqueueSnackbar } = useSnackbar()

  const options = {
    anchorOrigin: { horizontal: 'center', vertical: 'top' },
  }
  
  function showSuccess(message) {
    return enqueueSnackbar(message, { ...options, variant: 'success' })
  }

  function showWarning(message) {
    return enqueueSnackbar(message, { ...options, variant: 'warning' })
  }

  function showError(message) {
    return enqueueSnackbar(message, { ...options, variant: 'error' })
  }

  return { showSuccess, showWarning, showError }
}