import React from 'react';
import { GenericTable } from '../../common/GenericTable';
import moment from 'moment';
import { ButtonsRow } from '../../common/ButtonsRow';
import EditModal from './EditModal';


export function WorkingHoursTable({ data, onDelete, onEdit, projects, categories }) {

  const columns = React.useMemo(
    () => [
      {
        header: 'Proyecto',
        accessor: row => row.project.name,
        width: '14%'
      },
      {
        header: 'Categoría',
        accessor: row => row.working_hour_category.name,
        width: '14%'
      },
      {
        header: 'Descripción',
        accessor: row => row.description,
        width: '33%'
      },
      {
        header: 'Horas',
        accessor: row => row.hours,
        width: '10%'
      },
      {
        header: 'Fecha',
        accessor: row => moment(row.date_worked).format("DD/MM/YY"),
        width: '20%'
      },
      {
        header: 'Acciones',
        accessor: row => {
          function EditModalWrapper(props) {
            return <EditModal
              onEdit={onEdit}
              row={row}
              projects={projects}
              categories={categories}
              {...props}
            />
          }
          return <ButtonsRow
            row={row}
            onDelete={onDelete}
            EditComponent={EditModalWrapper}
          />
        },
      },
    ],
    [onDelete, categories, onEdit, projects]
  )

  return <GenericTable data={data} columns={columns} />
}