import React, { useCallback, useContext, useMemo } from 'react';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import { green, teal } from '@material-ui/core/colors';
import workingHoursProvider from '../../../api/working-hours';
import { GaiaDateFilter } from './components/GaiaDateFilter'
import { datesFilterStore } from '../../../context/DateContext';
import GenericToolbar from '../../common/toolbar/GenericToolbar';


export default function HomeToolbar({ tableUpdated, projects, categories }) {
  const { state } = useContext(datesFilterStore)
  const { dateFrom, dateTo } = state

  const Cards = [
    {
      content: "Horas totales",
      getData: useCallback(() => workingHoursProvider.getTotalHoursForUser(dateFrom, dateTo, projects, categories), [dateFrom, dateTo, projects, categories]),
      onResponse: useCallback(response => response.hours, []),
      dependencies: useMemo(() => [dateFrom, dateTo, tableUpdated, projects, categories], [dateFrom, dateTo,  tableUpdated, projects, categories]),
      contentColor: green[700],
      iconComponent: UpdateOutlinedIcon,
    },
    {
      content: "Promedio de horas diarias",
      getData: useCallback(() => workingHoursProvider.getAverageHoursForUser(dateFrom, dateTo, projects, categories), [dateFrom, dateTo, projects, categories]),
      onResponse: useCallback(response => response.hours, []),
      dependencies: useMemo(() => [dateFrom, dateTo, tableUpdated, projects, categories], [dateFrom, dateTo, tableUpdated, projects, categories]),
      contentColor: teal[700],
      iconComponent: BarChartOutlinedIcon,
    },
  ]

  return <GenericToolbar Cards={Cards}> <GaiaDateFilter/> </GenericToolbar>
}