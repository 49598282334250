import React, { useState } from 'react';
import moment from 'moment';
import { AddWorkingHourItem } from '../add-working-hour/components/AddWorkingHourItem';
import workingHoursProvider from '../../../api/working-hours';
import { getUser } from '../../../api/authentication';
import GenericModal from '../../common/GenericModal';

function defaultItem(row) {
  return {
    project: row.project.id,
    category: row.working_hour_category.id,
    description: row.description,
    hours: row.hours,
    date_worked: moment(row.date_worked),
  }
}

export default function EditModal({
  open,
  handleClose,
  onEdit,
  row,
  projects,
  categories
}) {
  const user = getUser()
  const [data, setData] = useState(defaultItem(row))

  const save = () => {
    //comunicarme con el backend
    //primero se recorre data y se formatea segun la estructura que espera el backend
    const mappedData = {
      project_id: data.project,
      working_hour_category_id: data.category,
      hours: data.hours,
      date_worked: moment(data.date_worked).format("YYYY-MM-DD"),
      description: data.description,
      user_id: user.id
    }

    workingHoursProvider.editWorkingHours(row.id, mappedData).then(editedRow => {
      //reemplazar el objeto por el objeto editado
      onEdit(editedRow)
    }).finally(() => handleClose())

  }

  const onProjectSelect = (value) => {
    const newData = {...data}
    newData.project = value
    setData(newData)
  }

  const onCategorySelect = (value) => {
    const newData = {...data}
    newData.category = value
    setData(newData)
  }

  const onDescriptionSet = (value) => {
    const newData = {...data}
    newData.description = value
    setData(newData)
  }

  const onHoursSet = (value) => {
    const newData = {...data}
    newData.hours = value
    setData(newData)
  }

  const onDateSelect = (value) => {
    const newData = {...data}
    newData.date_worked = value
    setData(newData)
  }

  return <GenericModal
    open={open}
    handleClose={handleClose}
    handleAccept={save}>

    <h2 id="transition-modal-title">Editar</h2>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <AddWorkingHourItem
        projects={projects}
        onProjectSelect={onProjectSelect}
        categories={categories}
        onCategorySelect={onCategorySelect}
        onDescriptionSet={onDescriptionSet}
        onHoursSet={onHoursSet}
        onDateSelect={onDateSelect}
        project={data.project}
        category={data.category}
        description={data.description}
        hours={data.hours}
        dateWorked={data.date_worked} />
    </div>
  </GenericModal>
}
