import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles({
  card: {
    flex: 0.20,
    display: "flex",
    flexDirection: 'column'
  },
  cardContent: {
    display: "flex",
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }
})

export default function SummaryCard({ content, getData, checkFunc, fallbackContent, onResponse, dependencies, contentColor, Icon, extraProps }) {
  const classes = useStyles()
  const [value, setValue] = useState()
  const [scontent, setSContent] = useState(content)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData()
      .then(response => {
        if (checkFunc && checkFunc(response)){
          setSContent(fallbackContent)
        }
        else{
          setValue(onResponse(response))
          setSContent(content)
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [dependencies, getData, onResponse, checkFunc, fallbackContent, content])

  return (
    <Card
      className={classes.card}
      variant="outlined">
      <CardContent
        className={classes.cardContent}>

        <Typography
          variant="subtitle1"
          component="h2"
          style={{ color: contentColor }}
          align="center">
          <Icon fontSize="large"></Icon>
        </Typography>

        <Typography
          variant="subtitle1"
          component="h2"
          style={{ color: contentColor }}
          align="center"
          {...extraProps}>

          {(!error) ? <>
            {scontent} {loading ?
              <CircularProgress color={contentColor} size={15} /> : <b>{value}</b>
            }
          </> : "Ocurrió un error :("}
        </Typography>

      </CardContent>
    </Card>
  )
}