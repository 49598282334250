import { makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 0)
  }
}))

export default function Paginator({ pageCount, page, setPage }) {
  const classes = useStyles()
  
  return <div className={classes.container}>
    <Pagination page={page} count={pageCount} onChange={(_, value) => setPage(value)} color="secondary" />
  </div>
}