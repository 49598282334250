import { Typography, useTheme } from "@material-ui/core";
import LocalAirportOutlinedIcon from '@material-ui/icons/LocalAirportOutlined';


export default function CurrentVacationsCardTitle({ title }) {
  const theme = useTheme()
  return (
    <Typography data-cy="current-vacations-title" variant="body1">
      <div style={{ display: 'flex', gap: theme.spacing(2), justifyContent: 'center' }}>
        <LocalAirportOutlinedIcon color="secondary" />
        {title}
      </div>
    </Typography>
  )
}