import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import React, { createContext, useReducer } from 'react';
import { green } from "@material-ui/core/colors";

const initialState = {
  darkMode: JSON.parse(localStorage.getItem('darkMode')),
};

const themeStore = createContext(initialState);
const { Provider } = themeStore;

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: green[600],
    },
    secondary: {
      main: '#FFA724'
    },
    success: green,
  }
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: green,
    secondary: {
      main: '#FFA724'
    },
    background: {
      default: "#212121",
      paper: "#212121"
    }
  }
})


const reducer = (state, darkMode) => {
  let newState = { ...state }
  newState.darkMode = darkMode
  localStorage.setItem('darkMode', darkMode)
  return newState
}


const DarkThemeProvider = ({ children }) => {
  const [state, setDarkMode] = useReducer(reducer, initialState);
  return <Provider value={{ state, setDarkMode }}>
    <ThemeProvider theme={state.darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </Provider>
}

export { themeStore, DarkThemeProvider }