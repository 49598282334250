import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from "@date-io/date-fns";
import Login from "../login";
import { getUser } from "../../api/authentication";
import { registerReponseHandlers } from "../../api/api-base";
import Main from "../Main";
import { HomeComponent } from "../home/HomeComponent";
import Statistics from "../statistics/Statistics";
import { DatesProvider } from "../../context/DateContext";
import VacationSection from "../vacations/VacationSection";
import GeneralVacationsSection from "../vacations/general-vacations/GeneralVacations";


export const ROUTES = {
  TIME_ENTRIES: "/home/timeEntries",
  STATISTICS: "/home/statistics",
  VACATION: "/home/vacation",
  GENERAL_VACATIONS: "/home/general-vacations"
}

function LoginRedirect() {
  const user = getUser()
  return !user ? <Login /> : <Redirect to={ROUTES.TIME_ENTRIES} />
}

function AppRouter() {
  const history = useHistory()
  registerReponseHandlers(history)
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}>
      <SnackbarProvider>
        <Route path="/" exact>
          <LoginRedirect />
        </Route>
        <DatesProvider>
          <Route path={ROUTES.TIME_ENTRIES} exact>
            <Main selected="home" Component={HomeComponent} />
          </Route>
        </DatesProvider>
        <Route path={ROUTES.GENERAL_VACATIONS} exact>
          <Main selected="general-vacations" Component={GeneralVacationsSection} />
        </Route>
        <Route path={ROUTES.VACATION} exact>
          <Main selected="vacation" Component={VacationSection} />
        </Route>
        <Route path={ROUTES.STATISTICS} exact>
          <Main selected="stats" Component={Statistics} />
        </Route>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  );
}

export default AppRouter;
