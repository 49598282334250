
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getMonth } from "../../utils";
import TabContent from "./TabContent";

const localizer = momentLocalizer(moment)
export default function CalendarTab({ value, setMonth, vacations }) {
  return (
    <TabContent index={0} value={value}>
      <div style={{ margin: '16px' }}>
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          onNavigate={newDate => setMonth(getMonth(moment(newDate)))}
          views={['month']}
          popup
          events={vacations}
          style={{ height: "100vh" }}
          eventPropGetter={(event) => ({ style: { backgroundColor: event.color } })}
          dayPropGetter={() => ({ style: { backgroundColor: 'inherit' } })}
        />
      </div>
    </TabContent>
  )
}