import React from 'react';
import GenericModal from '../../common/GenericModal'

export default function DeleteConfirmation({
  handleClose,
  handleDelete,
  open
}) {

  return <GenericModal
    open={open}
    handleClose={handleClose}
    handleAccept={handleDelete}>
    <h3 id="transition-modal-description">¿Estás seguro que querés eliminar?</h3>
    <h5> Mirá que podés hacer cagadas</h5>
  </GenericModal>
}