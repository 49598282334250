import { capitalizeFirst } from "../../utils";
import TabContent from "./TabContent";
import { useTheme, Paper } from "@material-ui/core";

function getHSL(value, isDarkMode) {
  /**
   * La idea es en base a un valor, obtener un codigo hsl de color.
   * Si el valor es menor o igual a 15, que es la cantidad de días que tenemos de vacaciones, sigue la función y = 120 - 14/3x 
   * Si el valor es mayor a 15, sigue la función y = 125 - 5x 
   * Además, si el valor es mayor a 25, la saturación aumenta para darle más gravedad al color.
   */
  const degrees = value <= 15 ? 120 - (4.66 * value) : 125 - (5 * value)
  const saturation = value > 25 ? (isDarkMode ? 60 : 80) : (isDarkMode ? 40 : 60)
  return `hsl(${degrees}deg, ${saturation}%, ${isDarkMode ? 46 : 85}%)`
}

function getDayDisplay(days) {
  if (days === 0) return "Sin días"
  if (days === 1) return "1 día"
  return `${days} días`
}

function ColoredCell({ colorValue, content }) {
  const theme = useTheme()
  console.log(theme.palette.text);
  return <div style={{
    textAlign: 'center',
    backgroundColor: getHSL(colorValue, theme.palette.type === "dark"),
    color: theme.palette.text,
    padding: theme.spacing(0.5)
  }}>
    {content}
  </div>
}
function ColoredRow({ row }) {
  return <>
    <ColoredCell colorValue={row.total} content={capitalizeFirst(row.username)} />
    <ColoredCell colorValue={row.total} content={getDayDisplay(row.total)} />
  </>
}

function Header({ children }) {
  const theme = useTheme()
  return <div style={{ textAlign: 'center', fontWeight: 800, marginBottom: theme.spacing(1) }}>{children}</div>
}

function GridTable({ children }) {
  const theme = useTheme()
  return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', minWidth: 500, margin: theme.spacing(2) }}>
    {children}
  </div>
}
export default function ResumeTab({ value, vacations }) {
  const theme = useTheme()
  return <div style={{ margin: theme.spacing(2.5, 0) }}>
    <TabContent index={1} value={value}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Paper elevation={3}>
          <GridTable>
            <Header>Socix</Header>
            <Header>Total</Header>
            {vacations.map(vacation => <ColoredRow row={vacation} />)}
          </GridTable>
        </Paper>
      </div>
    </TabContent>
  </div>
}