import React from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import AcceptCancelButtons from './AcceptCancelButtons'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 'none',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function GenericModal({
    open,
    handleClose,
    handleAccept,
    children
}) {
    const classes = useStyles();

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <div className={classes.paper}>
                {children}
                <AcceptCancelButtons
                    accept={handleAccept}
                    cancel={handleClose}
                    acceptLabel='Confirmar' />
            </div>
        </Fade>
    </Modal>
}