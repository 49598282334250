import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import workingHoursProvider from '../../../api/working-hours';
import { AddWorkingHourItem } from './components/AddWorkingHourItem';
import { getUser } from '../../../api/authentication';
import DeleteButton from './components/DeleteButton';
import AcceptCancelButtons from '../../common/AcceptCancelButtons';


function defaultItem(newElementDate) {
  return {
    tempId: Math.floor(Math.random() * 500),
    project: {},
    category: {},
    description: null,
    hours: null,
    date_worked: newElementDate
  }
}

export function AddWorkingHourComponent({ projects, categories, setAdding, onAdd, onAddStart }) {
  const user = getUser()
  const [data, setData] = useState([
    defaultItem(moment())
  ])

  const addNewRegister = () => {
    //copia la lista de elementos actuales y la asigna a newList
    const newList = [...data]
    //si la lista está vacia se agrega un elemento con la fecha del dia hoy, sino se agrega un elemento 
    //con la fecha del ultimo de la lista
    const newElementDate = newList.length === 0 ? moment() : newList[newList.length - 1].date_worked

    newList.push(defaultItem(newElementDate))

    setData(newList)
  }

  const deleteRegister = (index) => {
    setData(oldData => {
      const newList = [...oldData]
      newList.splice(index, 1)
      return newList
    })
  }

  const onPropertyChange = (property, index, value) => {
    const newData = [...data]
    newData[index][property] = value
    setData(newData)
  }

  const onProjectSelect = (index) => {
    return (value) => {
      onPropertyChange('project', index, value)
    }
  }

  const onCategorySelect = (index) => {
    return (value) => {
      onPropertyChange('category', index, value)
    }
  }

  const onDescriptionSet = (index) => {
    return (value) => {
      onPropertyChange('description', index, value)
    }
  }

  const onHoursSet = (index) => {
    return (value) => {
      onPropertyChange('hours', index, value)
    }
  }

  const onDateSelect = (index) => {
    return (value) => {
      onPropertyChange('date_worked', index, value)
    }
  }

  function handleCancel() {
    setAdding(false)
  }

  function handleAdd() {
    if (user === undefined) {
      // showMessage("No estas logeado", 'error')
      return
    }

    onAddStart(true)

    const mappedData = data.map(d => {
      return {
        project_id: d.project,
        working_hour_category_id: d.category,
        hours: d.hours,
        date_worked: moment(d.date_worked).format("YYYY-MM-DD"),
        description: d.description,
        user_id: user.id
      }
    })

    workingHoursProvider.saveWorkingHours(mappedData).then(workingHourList => {
      onAdd(workingHourList)
    }).finally(() => onAddStart(false))
  }

  return (
    <>
      {data.map((d, index) => {
        return <div key={`div-add-working-hour-item-${d.tempId}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <AddWorkingHourItem
            index={index}
            projects={projects}
            onProjectSelect={onProjectSelect(index)}
            categories={categories}
            onCategorySelect={onCategorySelect(index)}
            onDescriptionSet={onDescriptionSet(index)}
            onHoursSet={onHoursSet(index)}
            dateWorked={d.date_worked}
            onDateSelect={onDateSelect(index)}
          />
          <DeleteButton
            deleteRegister={deleteRegister}
            index={index}
          />
        </div>
      })}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          style={{ backgroundColor: grey[400], color: 'white', height: '100%' }}
          onClick={addNewRegister}
          data-cy='addRow'>
          <AddIcon />
        </IconButton>
      </div>
      <AcceptCancelButtons
        accept={handleAdd}
        cancel={handleCancel}
        acceptLabel='Guardar'
      />
    </>
  )
}