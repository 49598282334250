import { useTheme } from '@material-ui/core';
import React from 'react';
import SummaryCard from './SummaryCard'

/**
 * 
 * @param {Cards} Cards Una lista de cards. Cada card tiene la forma 
 * {
 *   content: Titulo de la card,
 *   getData: Una función async e inmutable para obtener el dato. Ej: useCallback(() => vacationsProvider.getTotalDaysForUser(), []),
 *   onResponse: Una función inmutable para procesar la respuesta de getData. Ej: useCallback(response => response.total, []),
 *   dependencies: Una lista de dependencias para recalcular el valor de la card. Puede ser util useMemo.
 *   contentColor: Color del contenido de la card.
 *   iconComponent: Un ícono para la card.
 *   checkFunc: una función inmutable del tipo response => bool. Si se cumple el chequeo fallbackContent se va a usar en vez de content.
 *   fallbackContent: un contenido alternativo a ser usado en caso de que checkFunc sea true.
 *   props: propiedades extra a ser pasadas al div que contiene al texto de la card, por ejemplo, para fines de testing.
 * }
 * @param {endCard} endCard Un contenido a ser ubicado al final de la lista de Cards.
 */
export default function GenericToolbar({ Cards, children, endCard }) {
  const theme = useTheme()
  return (
    <div style={{ margin: theme.spacing(2), display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: theme.spacing(4) }}>
      {children}
      {Cards.map((card, index) => {
        return (
          <SummaryCard
            key={index}
            content={card.content}
            getData={card.getData}
            onResponse={card.onResponse}
            dependencies={card.dependencies}
            contentColor={card.contentColor}
            Icon={card.iconComponent}
            checkFunc={card.checkFunc}
            fallbackContent={card.fallbackContent}
            extraProps={card.props || {}}
          />
        )
      })}
      {endCard}
    </div>
  )
}
