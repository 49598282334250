import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';


export function GaiaDatePicker(props) {
  return (
    <KeyboardDatePicker 
      autoOk={true} 
      margin="dense"
      id="date-picker-dialog"
      label={props.label}
      format="dd/MM/yy"
      value={props.date}
      onChange={props.changeDate}
      KeyboardButtonProps={{
        'data-cy':props.dataCy,
        'aria-label': 'change date',
      }}
      {...props}
    />
  )
}

