import { Chip, useTheme } from "@material-ui/core";
import { useState } from "react";


export default function Filter({ name, selectedValues, setSelected, allValues }) {
  const theme = useTheme()
  const [active, setActive] = useState(false)

  function handleFilterClick(id) {
    if (selectedValues.length + 1 === allValues.length)
      clearFilter()
    else {
      if (selectedValues.includes(id))
        setSelected(selectedValues.filter(value => value !== id))
      else
        setSelected([id, ...selectedValues])
    }
  }

  function clearFilter() {
    setSelected([])
    setActive(false)
  }
  return (
    <div style={{ display: 'flex', gap: theme.spacing(0.5) }}>
      <Chip
        style={{ marginRight: theme.spacing(0.5) }}
        onDelete={selectedValues.length > 0 && clearFilter}
        label={name} size="small"
        color="primary"
        variant={active ? "default" : "contained"}
        onClick={() => setActive(act => !act)}
      />

      {active && (
        allValues.map(value => <Chip color={selectedValues.includes(value.id) ? "secondary" : "default"} onClick={() => handleFilterClick(value.id)} label={value.name} size="small" />)
      )}
    </div>
  )
}