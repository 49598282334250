import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            width: 100,
        },
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1),
    },
}));

export function DaysWrapper(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TextField
                onChange={ (evento) => props.changeDays(evento.target.value)}
                id="outlined-basic"
                label="Días"
                size="small"
                variant="outlined" />
        </div>
    )
}

