import React, { useContext } from 'react';
import * as moment from 'moment'
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { datesFilterStore } from '../../../../context/DateContext';
import { GaiaDatePicker } from './GaiaDatePicker';


const useStyles = makeStyles({
  card: {
    flex: 0.2,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export function GaiaDateFilter() {
  const classes = useStyles();
  const { state, dispatch } = useContext(datesFilterStore)
  const { dateFrom, dateTo } = state

  function getChangeDateFn(type){
    return (date) => {
      dispatch({ type, data: date && moment(date) })
    }
  }

  const changeFromDate = getChangeDateFn('store_date_from')

  const changeToDate = getChangeDateFn('store_date_to')

  return (
    <Card
      className={classes.card}
      variant="outlined">
      <CardContent>

        <div style={{ display: "flex", flexDirection: 'column' }}>
          <GaiaDatePicker
            label="Desde"
            date={dateFrom}
            changeDate={changeFromDate} />
          <GaiaDatePicker
            label="Hasta"
            date={dateTo}
            changeDate={changeToDate} />
        </div>

      </CardContent>
    </Card>
  )
}