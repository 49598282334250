import React from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

export function HoursTimePicker(props) {

    return (
        <KeyboardTimePicker
            {...props}
            placeholder="08:00 AM"
            ampm={false}
            style={{ width: '16%', flexGrow: 1, margin: '15px' }}
            keyboardIcon={<AccessAlarmIcon/>}

            mask="__:__ _M"
            value={props.value}
            onChange={date => props.onChange(date)}
        />
    );
}

