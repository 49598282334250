import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';


export default function ItemSelect(props) {
  
    const onChange = e => {
      props.editTable(e.target.value)
    }
  
    return <TextField
      {...props}
      style={{ width: '15%', flexGrow: 1, margin: '15px' }}
      select
      value={props.value}
      onChange={onChange}>
      {props.elements.map(e => <MenuItem value={e.id}>{e.name}</MenuItem>)}
    </TextField>
  }