import { Typography, Tooltip } from "@material-ui/core";
import { beautifyDate } from "../../utils";


function DaysDisplay({ days_left }) {
  if (days_left === 0) return <><GrayText text="comienza " /><b>hoy</b></>
  if (days_left === 1) return <><GrayText text="comienza " /><b>mañana</b></>
  return <><GrayText text="dentro de " /><b>{days_left}</b> <GrayText text="días" /></>
}

function GrayText({ text }) {
  return <span style={{ color: 'gray' }}>{text}</span>
}

export default function NextVacationsCardItem({ vacation, index }) {
  return (
    <Tooltip key={`next-vacation${index}`} title={`comienza el ${beautifyDate(vacation.start_date)}`}>
      <Typography data-cy={`next-vacation${index}`} variant="subtitle1">
        {vacation.name} <DaysDisplay days_left={vacation.days_left} />
      </Typography>
    </Tooltip>
  )
}