import { useTheme } from "@material-ui/core"
import FilterListIcon from '@material-ui/icons/FilterList';

export default function FIlterWrapper({ active, children }) {
  const theme = useTheme()

  return <div style={{ display: 'flex', marginLeft: theme.spacing(2) }}>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <FilterListIcon color={active ? 'secondary' : 'default'} />
    </div>
    <div style={{ display: 'flex', marginLeft: theme.spacing(1.5), gap: theme.spacing(0.5), flexDirection: 'column' }}>
      {children}
    </div>
  </div>
}