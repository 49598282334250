import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import ButtonWrapper from '../../../common/ButtonWrapper';

export default function DeleteButton({ deleteRegister, index }) {
    return <ButtonWrapper>
        <IconButton
            onClick={() => deleteRegister(index)} data-cy='DeleteRow'>
            <DeleteIcon />
        </IconButton>
    </ButtonWrapper>

}

