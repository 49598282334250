import { useState } from "react";

export function usePagination(pageSize) {
  const [page, setPage] = useState(1)
  const [entriesCount, setEntriesCount] = useState(0)

  return [
    page,
    setPage,
    setEntriesCount,
    entriesCount > pageSize,
    //Si tenemos 10 elementos y una pagina de 5, el Math.floor nos devuelve esas 2 páginas necesarias.
    //Si tenemos 11, el math.floor también nos diría 2 páginas, pero necesitamos 3. Es por eso que si el resto de dividir la
    //cantidad de entradas por el tamaño de las páginas no es 0 (11/5 tiene resto 1) sumamos una página más
    Math.floor(entriesCount / pageSize) + (entriesCount % pageSize === 0 ? 0 : 1)
  ]
}