import React, { useCallback } from 'react';
import { green } from '@material-ui/core/colors';
import vacationsProvider from '../../api/vacations';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import GenericToolbar from '../common/toolbar/GenericToolbar';
import { Button, makeStyles } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../routes/routes';

const useStyles = makeStyles(() => ({
  containedSecondary: {
    color: 'white'
  }
}))

function GeneralCalendarButton() {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div>
        <Button classes={classes} variant="contained" color="secondary" endIcon={<EventAvailableIcon />} onClick={() => history.push(ROUTES.GENERAL_VACATIONS)}>
          Ver calendario
        </Button>
      </div>
    </div>
  )
}

export default function VacationToolbar({ vacationEntries }) {
  const Cards = [
    {
      content: "Días totales",
      getData: useCallback(() => vacationsProvider.getTotalDaysForUser(), []),
      onResponse: useCallback(response => response.total, []),
      dependencies: vacationEntries,
      contentColor: green[700],
      iconComponent: LocalBarIcon,
    },
  ]

  return <GenericToolbar Cards={Cards} endCard={<GeneralCalendarButton />} />
}

