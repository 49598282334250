import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    }
}))

export default function ButtonWrapper({ children }) {
    const classes = useStyles();

    return <div className={classes.wrapper} >
        {children}
    </div>
}