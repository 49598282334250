import axios from 'axios';

const axios_auth = axios.create()

axios_auth.interceptors.request.use(setToken);

function setToken(config) {
  config.headers.Authorization = getToken()
  return config;
}

export function registerReponseHandlers(history) {
  function errorHandler(error) {
    if (error.response?.status === 401) {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      if (history.location.pathname !== '/')
        history.replace('', { variant: 'warning', message: 'Te deslogueamos porque tus credenciales están en cualquiera' })
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  }

  axios_auth.interceptors.response.use(undefined, errorHandler)
}

function getToken() {
  return `Token ${localStorage.getItem("token")}`
}

export { axios, axios_auth }