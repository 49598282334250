import React from 'react';
import { TextField } from '@material-ui/core';


export function HoursTextField(props) {

  const onChange = e => {
    props.editTable(e.target.value);
  };

  return <TextField
    {...props}
    style={{ width: '10%', flexGrow: 1, margin: '15px' }}
    value={props.value}
    onChange={onChange} />;
}
