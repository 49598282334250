import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
  
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 600,
          },
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1),
    },
  }));

export function DescriptionWrapper ({ onChange, value }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TextField 
            value={value}
            onChange={ (evento) => onChange(evento.target.value)}
            id="outlined-basic" 
            label="Descripción" 
            size="small" 
            variant="outlined"/>        
        </div>
    )
}

