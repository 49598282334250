import Link from '@material-ui/core/Link';

export default function ReleaseName() {
    return <code>
        <span className="green">
            gaia 1.6.0
              <Link 
                style={{ marginLeft: '10px', marginRight: '10px' }}
                className="disappear green"
                underline="none"
                color="default"
                href="https://en.wikipedia.org/wiki/Barbara_Liskov"
                target="_blank">
                Barbara_
              </Link>
        </span>
    </code>
}
