import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import DeleteConfirmation from '../home/working-hours-table/DeleteConfirmation';
import useOpenClose from '../../hooks/useOpenClose';


export function ButtonsRow({
    row,
    onDelete,
    EditComponent
}) {
    const [openEdit, handleOpenEdit, handleCloseEdit] = useOpenClose()
    const [openDelete, handleOpenDelete, handleCloseDelete] = useOpenClose()

    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      {openEdit && <EditComponent 
            open={openEdit}
            handleClose={handleCloseEdit}
      />}
        {EditComponent && <IconButton
            onClick={handleOpenEdit}
        >
            <EditIcon />
        </IconButton>
        }

        {openDelete && <DeleteConfirmation
            handleDelete={() => {
                onDelete(row.id).finally(() => {
                    handleCloseDelete()
                })
            }}
            open={openDelete}
            handleClose={handleCloseDelete}
        />}
        <IconButton
            style={{ color: red[400] }}
            onClick={handleOpenDelete}>

            <DeleteIcon />
        </IconButton>
    </div >
}