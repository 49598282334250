import React, { createContext, useReducer } from 'react';
import * as moment from 'moment';

const initialState = {
  dateFrom: moment().startOf('month'),
  dateTo: moment().endOf('month')
};

const datesFilterStore = createContext(initialState);
const { Provider } = datesFilterStore;


const reducer = (state, action) => {
  let newState = { ...state }
  switch (action.type) {
    case 'store_date_from':
      newState.dateFrom = action.data
      return newState
    case 'store_date_to':
      newState.dateTo = action.data
      return newState

    default:
      throw new Error(`Invalid action ${action.type}`)
  }
}


const DatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { datesFilterStore, DatesProvider }