import { axios_auth } from './api-base';
import { API_URL } from './environment'

const getProjects = () => {
  return axios_auth.get(`${API_URL}projects/`).then(response => response.data)
}

const service = {
  getProjects,
}
export default service