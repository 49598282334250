import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import AcceptCancelButtons from '../common/AcceptCancelButtons';
import { GaiaDatePicker } from '../home/toolbar/components/GaiaDatePicker';
import { DaysWrapper } from './components/DaysWrapper';
import { DescriptionWrapper } from './components/DescriptionWrapper';
import { getUser } from '../../api/authentication';
import vacationsProvider from '../../api/vacations';
import Chip from '@material-ui/core/Chip';
import { Typography, useTheme } from '@material-ui/core';
import { midnight } from './utils';
import useMessages from '../../hooks/useMessages';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

export default function AddVacation({ handleCancel, onAdd, vacationCategories }) {
  const classes = useStyles();
  const user = getUser()
  const theme = useTheme()
  const [selectedCategory, setSelectedCategory] = useState()
  const [startDay, setStartDay] = useState(midnight())
  const [endDay, setEndDay] = useState(midnight())
  const [endDayEdited, setEndDayEdited] = useState(false)
  const [description, setDescription] = useState()
  const [days, setDays] = useState(1)
  const [dateError, setDateError] = useState(false)
  const { showSuccess, showError } = useMessages()


  function handleAdd() {
    const vacationEntry = {
      category_code: selectedCategory,
      description: description,
      days: days,
      date_created: moment().format("YYYY-MM-DD"),
      start_date: startDay.format("YYYY-MM-DD"),
      end_date: endDay.format("YYYY-MM-DD"),
      user: user.id
    }
    vacationsProvider.saveVacation(vacationEntry).then(newVacationEntry => {
      onAdd(newVacationEntry)
      showSuccess('Se agregó correctamente')
    }).catch(error => {
      showError('Ocurrió un problema al guardar')
    }).finally(() => handleCancel())
  }

  function handleStartDay(date) {
    const newStartDay = midnight(moment(date))
    setStartDay(newStartDay)
    if (!endDayEdited) {
      setEndDay(newStartDay)
    }
    else{
      if (!newStartDay.isAfter(endDay)) {
        setDateError(false)
      }
      else {
        setDateError(true)
      }
    }
  }

  function handleEndDay(date) {
    const newEndDay = midnight(moment(date))
    setEndDay(newEndDay)
    setEndDayEdited(true)
    if (!startDay.isAfter(newEndDay)) {
      setDateError(false)
    }
    else {
      setDateError(true)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <div className={classes.root}>
            {vacationCategories.map((category, index) => {
              return <Chip label={category.name} onClick={() => setSelectedCategory(category.code)}
                color={selectedCategory === category.code ? "secondary" : "default"} />
            })}
          </div>
          {selectedCategory && <> {(selectedCategory === 'TOMA' ? <div style={{ margin: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{display:'flex', gap:theme.spacing(2)}}>
              <GaiaDatePicker
                label="Desde"
                changeDate={handleStartDay}
                date={startDay}
              />

              <GaiaDatePicker
                label="Hasta"
                changeDate={handleEndDay}
                date={endDay}
              />
            </div>

            {dateError &&
              <Typography variant="caption" color="error">
                La fecha de inicio no puede ser anterior a la fecha de fin
              </Typography>}

          </div> : <DaysWrapper
            changeDays={setDays} />)}


            <DescriptionWrapper
              onChange={setDescription}
              value={description}
            />
          </>}

        </div>

      </div>

      <AcceptCancelButtons
        accept={handleAdd}
        cancel={handleCancel}
        acceptLabel='Guardar'
      />

    </>
  )
}