import { useEffect, useState } from "react";
import vacationsProvider from '../../../api/vacations';
import moment from 'moment';
import { useTheme } from "@material-ui/core";
import { capitalizeFirst, midnight } from "../utils";
import VacationsCard from "./toolbar/VacationsCard";
import NextVacationsCardTitle from "./toolbar/NextVacationsCardTitle";
import NextVacationsCardItem from "./toolbar/NextVacationsCardItem";
import CurrentVacationsCardTitle from "./toolbar/CurrentVacationsCardTitle";
import CurrentVacationsCardItem from "./toolbar/CurrentVacationsCardItem";


function transformNextVacation(vacation) {
  const startDate = midnight(moment(vacation.start_date))
  return {
    name: capitalizeFirst(vacation.username),
    days_left: startDate.diff(midnight(), 'days'),
    start_date: startDate
  }
}

function transformCurrentVacation(vacation) {
  const endDate = midnight(moment(vacation.end_date))
  return {
    name: capitalizeFirst(vacation.username),
    days_left: endDate.diff(midnight(), 'days'),
    end_date: endDate
  }
}


export default function GeneralVacationsToolbar() {
  const theme = useTheme()
  const [nextVacations, setNextVacations] = useState([])
  const [currentVacations, setCurrentVacations] = useState([])

  useEffect(() => {
    vacationsProvider.getCurrentVacations().then(vacations => {
      setCurrentVacations(vacations.map(transformCurrentVacation))
    })
  }, [])

  useEffect(() => {
    vacationsProvider.getNextVacations().then(vacations => {
      setNextVacations(vacations.map(transformNextVacation))
    })
  }, [])

  return <div style={{ margin: theme.spacing(2), display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: theme.spacing(4) }}>
    <VacationsCard
      vacations={currentVacations}
      TitleComponent={() => <CurrentVacationsCardTitle title={currentVacations.length > 0 ? "Vacaciones actuales" : "No hay nadie de vacaciones"} />}
      ItemComponent={CurrentVacationsCardItem}
    />
    <VacationsCard
      vacations={nextVacations}
      TitleComponent={() => <NextVacationsCardTitle title={nextVacations.length > 0 ? "Próximas vacaciones" : "No hay próximas vacaciones"} />}
      ItemComponent={NextVacationsCardItem}
    />
  </div>
}