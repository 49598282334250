import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import VacationsCardItemContainer from "./VacationsCardItemContainer";


const useStyles = makeStyles({
  card: {
    flex: 0.20,
    display: "flex",
    flexDirection: 'column'
  },
  cardContent: {
    display: "flex",
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%'
  },
})




export default function VacationsCard({ vacations, TitleComponent, ItemComponent }) {
  const classes = useStyles()

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.cardContent}>
        <TitleComponent />
        {vacations.length > 0 && <VacationsCardItemContainer>
          {vacations.map((nv, i) => <ItemComponent vacation={nv} index={i} />)}
        </VacationsCardItemContainer>}
      </CardContent>
    </Card>
  )
}