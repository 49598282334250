import { axios_auth } from './api-base';
import { API_URL } from './environment';

const VACATION_ENTRY_URL = `${API_URL}vacation-entries/`


function getEntries() {
  return axios_auth.get(VACATION_ENTRY_URL).then(response => response.data).catch(error => console.log(error))
}

function saveVacation(vacationEntry) {
  return axios_auth.post(VACATION_ENTRY_URL, vacationEntry).then(response => response.data)
}

function getTotalDaysForUser() {
  return axios_auth.get(`${VACATION_ENTRY_URL}total/`).then(response => response.data).catch(error => console.log(error))
}

function deleteEntry(vacationEntry) {
  return axios_auth.delete(`${VACATION_ENTRY_URL}${vacationEntry}`).then(response => response.data).catch(() => null)
}

function getNextVacations() {
  return axios_auth.get(`${VACATION_ENTRY_URL}next_vacations/`).then(response => response.data).catch(error => console.log(error))
}

function getMonthVacations(month) {
  return axios_auth.get(`${VACATION_ENTRY_URL}month_vacations/`, { params: { month } }).then(response => response.data).catch(error => console.log(error))
}

function getCurrentVacations() {
  return axios_auth.get(`${VACATION_ENTRY_URL}current_vacations/`).then(response => response.data).catch(error => console.log(error))
}

function getTotalDaysByUser() {
  return axios_auth.get(`${VACATION_ENTRY_URL}total_by_user/`).then(response => response.data).catch(error => console.log(error))
}



const service = {
  getEntries,
  saveVacation,
  getTotalDaysForUser,
  deleteEntry,
  getNextVacations,
  getMonthVacations,
  getCurrentVacations,
  getTotalDaysByUser,
}

export default service