import { IconButton } from "@material-ui/core";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { useContext } from 'react';
import { themeStore } from "../../context/ThemeContext";

export default function DarkModeButton() {
  const { state, setDarkMode } = useContext(themeStore)
  function toggleOnDarkMode() {
    setDarkMode(true)
  }

  function toggleOffDarkMode() {
    setDarkMode(false)
  }

  return <div style={{ marginRight: '20px' }}>
    <IconButton onClick={state.darkMode ? toggleOffDarkMode : toggleOnDarkMode}>
      {state.darkMode ? <EmojiObjectsOutlinedIcon /> : <EmojiObjectsIcon />}
    </IconButton>
  </div>
}