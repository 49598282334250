import React, { useState, useEffect, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import workingHoursProvider from '../../api/working-hours';
import projectsProvider from '../../api/projects';
import workingHourCategoriesProvider from '../../api/working-hour-categories';
import { WorkingHoursTable } from './working-hours-table/WorkingHoursTable';
import HomeToolbar from './toolbar/HomeToolbar';
import { makeStyles } from '@material-ui/core/styles';
import { AddWorkingHourComponent } from './add-working-hour/AddWorkingHour';
import { datesFilterStore } from '../../context/DateContext';
import NavBar from '../common/NavBar'
import { Fab } from '@material-ui/core';
import Paginator from '../common/Paginator'
import { usePagination } from '../../hooks/usePagination'
import CircularProgress from '@material-ui/core/CircularProgress';
import useMessages from '../../hooks/useMessages';
import Filter from '../common/filter/Filter';
import FIlterWrapper from '../common/filter/FilterWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center'
  },
}));

export function HomeComponent() {
  const classes = useStyles();
  const { state } = useContext(datesFilterStore)
  const { dateFrom, dateTo } = state
  const [workingHours, setWorkingHours] = useState(null)
  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [filteredCategories, setFilteredCategories] = useState([])
  const [loadingState, setLoadingState] = useState({ loading: false, action: "" })
  const [tableUpdated, setTableUpdated] = useState(false)
  const [adding, setAdding] = useState(false)
  const { showSuccess } = useMessages()
  const [page, setPage, setEntriesCount, displayPagination, pageCount] = usePagination(50)


  useEffect(() => {
    function getProjects() {
      return projectsProvider.getProjects().then((projectList) => {
        setProjects(projectList)
      })
    }
    function getWorkingHoursCategories() {
      return workingHourCategoriesProvider.getWorkingHoursCategories().then((categoryList) => {
        setCategories(categoryList)
      })
    }

    getProjects()
    getWorkingHoursCategories()
  }, [])

  useEffect(() => {
    function getEntries() {
      setLoadingState({ loading: true, action: 'Cargando...' })
      return workingHoursProvider.getEntries(page, dateFrom, dateTo, filteredProjects, filteredCategories).then(data => {
        setEntriesCount(data.count)
        setWorkingHours(data.results)
      }).finally(() => setLoadingState({ loading: false }))
    }

    getEntries()
  }, [dateFrom, dateTo, page, setEntriesCount, filteredProjects, filteredCategories])

  function onDelete(workingHourId) {
    return workingHoursProvider.deleteEntry(workingHourId).then(() => {
      setWorkingHours(workingHours.filter(w => w.id !== workingHourId))
      setTableUpdated(!tableUpdated)
      showSuccess('Se eliminó correctamente')
    })
  }

  function onEdit(editedRow) {
    const workingHoursCopy = [...workingHours]
    const index = workingHoursCopy.findIndex(element => {
      return element.id === editedRow.id
    })
    workingHoursCopy.splice(index, 1, editedRow)
    setWorkingHours(workingHoursCopy)
    setTableUpdated(!tableUpdated)
    showSuccess('Se editó correctamente')
  }

  function addStart(load) {
    setLoadingState({ loading: load, action: 'Agregando...' })

  }

  function onAdd(items) {
    setAdding(false)
    const whs = items.concat(workingHours)
    setWorkingHours(whs)
    setTableUpdated(!tableUpdated)
    setLoadingState({ loading: false })
    showSuccess('Se agregó correctamente')
  }

  return (
    <>
      <NavBar
        title='Carga de horas' />

      <HomeToolbar
        tableUpdated={tableUpdated}
        projects={filteredProjects}
        categories={filteredCategories}
      />

      <FIlterWrapper active={filteredProjects.length > 0 || filteredCategories.length > 0}>
        <Filter
          name="Proyectos"
          selectedValues={filteredProjects}
          setSelected={setFilteredProjects}
          allValues={projects}
        />
        <Filter
          name="Categorias"
          selectedValues={filteredCategories}
          setSelected={setFilteredCategories}
          allValues={categories} />
      </FIlterWrapper>

      {loadingState.loading ?
        <div className={classes.root}>
          <CircularProgress />
        </div> : ''
      }

      {adding &&
        <AddWorkingHourComponent
          projects={projects}
          categories={categories}
          setAdding={setAdding}
          onAdd={onAdd}
          onAddStart={addStart} />}

      <WorkingHoursTable
        data={workingHours || []}
        onDelete={onDelete}
        onEdit={onEdit}
        projects={projects}
        categories={categories} />

      {displayPagination && <Paginator pageCount={pageCount} page={page} setPage={setPage} />}

      {!adding &&
        <Fab
          color="primary"
          aria-label="add"
          style={{ position: 'fixed', right: '25px', bottom: '25px' }}
          onClick={() => setAdding(true)}>
          <AddIcon />
        </Fab>}
    </>
  )
}