import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { login } from '../../api/authentication'
import "./index.css"
import logo from '../../resources/images/gaia.png';
import { useHistory } from 'react-router';
import { Grid, CircularProgress } from '@material-ui/core';
import ReleaseName from '../common/ReleaseName'

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#40b75a',
    },
    '& label': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#40b75a',
    },
    '& .MuiOutlinedInput-input': {
      color: '#40b75a',
      padding: '15px 14px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#40b75a',
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    color: '#08a045',
    borderColor: '#08a045'
  },
  errors: {
    'font-size': 'small'
  },
  loader: {
    color: '#08a045'
  }
}));

function Login() {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (history.location.state?.message) {
      enqueueSnackbar(history.location.state.message, {
        variant: history.location.state.variant,
        hideIconVariant: true,
        autoHideDuration: 5000
      })
    }
  }, [enqueueSnackbar, history.location.state])
  
  function updateUserName(event) {
    setUserName(event.target.value)
  }

  function updatePassword(event) {
    setPassword(event.target.value)
  }

  function doLogin() {
    setErrors([])
    setLoading(true)
    login(userName, password).then(() => {
      history.push('/home/timeEntries')
    }).catch((err) => {
      setErrors([err.message])
    }).finally(() => setLoading(false))
  }

  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      doLogin()
    }
  }

  const classes = useStyles();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="gaia-font">
          <ReleaseName/>
        </p>

        <Grid container>
          <Grid item xs={12}>
            <CssTextField
              label="Usuario"
              value={userName}
              onChange={updateUserName}
              variant="outlined"
              data-cy="usuario"
            />
          </Grid>

          <Grid item xs={12}>
            <CssTextField
              label="Contraseña"
              value={password}
              onChange={updatePassword}
              onKeyPress={handleKeyPress}
              type="password"
              margin="normal"
              variant="outlined"
              data-cy="contraseña"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={doLogin}
              variant="outlined"
              className={classes.button}
              data-cy="iniciar-sesion"
            >
              Iniciar Sesión
            </Button>
          </Grid>

          <Grid item xs={12} className={classes.errors}>
            <code data-cy="error">
              {errors.map(error => error)}
            </code>
          </Grid>

          <Grid item xs={12}>
            {loading && <CircularProgress className={classes.loader} size={25} />}
          </Grid>
        </Grid>
      </header>
    </div>

  )

}


export default Login;