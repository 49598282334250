import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NavBar from '../common/NavBar';
import { GenericTable } from '../common/GenericTable';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import vacationsProvider from '../../api/vacations';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { Fab } from '@material-ui/core';
import vacationCategoriesProvider from '../../api/vacation-categories';
import AddIcon from '@material-ui/icons/Add';
import AddVacation from './AddVacation';
import { makeStyles } from '@material-ui/core/styles';
import VacationToolbar from './VacationToolbar';
import { ButtonsRow } from '../common/ButtonsRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useMessages from '../../hooks/useMessages';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center'
  },
}));

export default function VacationSection() {
  const classes = useStyles();
  const [vacationEntries, setVacationEntries] = useState([])
  const [vacationCategories, setVacationCategories] = useState([])
  const [adding, setAdding] = useState(false)
  const { showSuccess } = useMessages()
  const [loadingState, setLoadingState] = useState({ loading: false })

  useEffect(() => {
    vacationCategoriesProvider.getVacationCategories().then(vacationCategoriesFromBackend => {
      setVacationCategories(vacationCategoriesFromBackend)
    })
  }, [])

  useEffect(() => {
    setLoadingState({ loading: true })
    vacationsProvider.getEntries().then(entries => {
      setVacationEntries(entries)
    }).finally(() => setLoadingState({ loading: false }))

  }, [])

  function handleCancel() {
    setAdding(false)
  }

  function onAdd(newVacationEntry) {
    const newVacationEntries = [newVacationEntry, ...vacationEntries]
    setVacationEntries(newVacationEntries)
  }

  function onDelete(vacationId) {
    return vacationsProvider.deleteEntry(vacationId).then(() => {
      setVacationEntries(vacationEntries.filter(vacationEntry => vacationEntry.id !== vacationId))
      showSuccess('Se eliminó correctamente')
    })
  }

  function formatDate(stringDate) {
    return moment(stringDate).format("DD-MM-YYYY")
  }

  const TableDays = ({ days }) => <Typography variant="body2">{days}</Typography>

  const columns = [
    {
      header: '',
      accessor: row => row.category.code === 'TOMA' ? <EventBusyIcon style={{ color: 'red' }} /> : <EventAvailableIcon style={{ color: 'green' }} />,
      width: '4%'
    },
    {
      header: 'Categoría',
      accessor: row => row.category.name,
      width: '14%'
    },
    {
      header: 'Descripción',
      accessor: row => row.description,
      width: '40%'
    },
    {
      header: 'Días',
      accessor: row => <TableDays days={row.days} />,
      width: '10%'
    },
    {
      header: 'Fecha',
      accessor: row => row.category.code === 'TOMA' && <Typography variant="caption">desde {formatDate(row.start_date)} hasta {formatDate(row.end_date)}</Typography>,
      width: '20%',
      align: 'center'
    },
    {
      header: 'Acciones',
      accessor: row => {
        return <ButtonsRow
          row={row}
          onDelete={onDelete}
        />
      }
    }
  ]


  return (
    <>
      <NavBar title='Vacaciones' />

      <VacationToolbar
        vacationEntries={vacationEntries} />

      {loadingState.loading ?
        <div className={classes.root}>
          <CircularProgress />
        </div> : ''
      }

      {adding &&
        <AddVacation
          onAdd={onAdd}
          handleCancel={handleCancel}
          vacationCategories={vacationCategories} />}

      <GenericTable
        data={vacationEntries}
        columns={columns} />

      {!adding &&
        <Fab
          color="primary"
          aria-label="add"
          style={{ position: 'fixed', right: '25px', bottom: '25px' }}
          onClick={() => setAdding(true)}
        >
          <AddIcon />
        </Fab>}
    </>
  );
};