import React from 'react';
import { Paper, Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

export function GenericTable({ data, columns, size = null }) {
  return (
    <Paper
      styles={{ width: '100%' }}>
      <Table
        stickyHeader
        size={size}
        aria-label="sticky table">

        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.id} align={column.align} style={{ width: column.width }}>{column.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(row => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {
                  columns.map(column => {
                    return <TableCell  align={column.align}>{column.accessor(row)}</TableCell>
                  })
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}