import NavBar from '../../common/NavBar';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import GeneralVacationsToolbar from './GeneralVacationsToolbar';
import { useEffect, useState } from 'react';
import vacationsProvider from '../../../api/vacations'
import { capitalizeFirst, getMonth, getRGB } from '../utils';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import { Tabs } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import CalendarTab from './tabs/CalendarTab'
import ResumeTab from './tabs/ResumeTab'

export default function GeneralVacationsSection() {
  const [vacations, setVacations] = useState([])
  const [vacationsDaysByUser, setVacationsDaysByUser] = useState([])
  const [month, setMonth] = useState(getMonth(moment()))
  const [tab, setTab] = useState(0)
  const history = useHistory()

  useEffect(() => {
    vacationsProvider.getMonthVacations(month).then(vacationEntries => {
      setVacations(vacationEntries.map(vacationEntry => ({
        start: moment(vacationEntry.start_date).toDate(),
        end: moment(vacationEntry.end_date).add(1, 'day').toDate(),
        title: `${capitalizeFirst(vacationEntry.user)} ${vacationEntry.description || ""}`,
        color: getRGB(vacationEntry.user + vacationEntry.description)
      })))
    })
  }, [month])

  useEffect(() => {
    vacationsProvider.getTotalDaysByUser().then(vacationEntries => {
      setVacationsDaysByUser(vacationEntries)
    })
  }, [month])

  return <>
    <NavBar title='Vacaciones generales' onBack={() => history.push(ROUTES.VACATION)} />
    <GeneralVacationsToolbar />
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Tabs textColor="primary" indicatorColor="primary" value={tab} onChange={(_, value) => setTab(value)} aria-label="tabs calendario y resumen">
        <Tab label="Calendario" />
        <Tab label="Resumen" />
      </Tabs>
    </div>
    <CalendarTab value={tab} vacations={vacations} setMonth={setMonth} />
    <ResumeTab value={tab} vacations={vacationsDaysByUser} />
  </>
}
