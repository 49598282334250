import { Typography, Tooltip } from "@material-ui/core";
import { beautifyDate } from '../../utils';


function DaysDisplay({ days_left }) {
  if (days_left === 0) return <><GrayText text="hasta " /><b>hoy</b></>
  if (days_left === 1) return <><GrayText text="hasta " /><b>mañana</b></>
  return <><GrayText text="vuelve en " /><b>{days_left}</b> <GrayText text="días" /></>
}

function GrayText({ text }) {
  return <span style={{ color: 'gray' }}>{text}</span>
}

export default function CurrentVacationsCardItem({ vacation, index }) {
  return (
    <Tooltip key={`current-vacation${index}`} title={`hasta el ${beautifyDate(vacation.end_date)}`}>
      <Typography data-cy={`current-vacation${index}`} variant="subtitle1">
        {vacation.name} <DaysDisplay days_left={vacation.days_left} />
      </Typography>
    </Tooltip>
  )
}