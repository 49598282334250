import { axios_auth } from './api-base';
import { API_URL } from './environment'

const VACATION_CATEGORIES_URL = `${API_URL}vacation-categories/`

function getVacationCategories () {
  return axios_auth.get(VACATION_CATEGORIES_URL).then(response => response.data)
}

const service = {
  getVacationCategories
}

export default service
