import moment from 'moment'

export function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function beautifyDate(date) {
  return moment(date).format("D/M")
}
export function midnight(date=moment()) {
  return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}
/**
 * 
 * @param {Un string para calcular el color} str 
 * @returns Un color hexadecimal basado en ese string
 */
export function getRGB(str) {
  return `#${intToRGB(hashCode(str))}`
}

export function getMonth(momentDate) {
  return momentDate.month() + 1
}

function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
     hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
} 


function intToRGB(i){
  var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}
