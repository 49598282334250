import { axios } from './api-base'
import { AUTH_URL } from './environment'

export function login(username, password) {
  return axios.post(AUTH_URL, {
    username: username,
    password: password,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function logout(){
  localStorage.clear()
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'))
}

function handleResponse(response) {
  localStorage.setItem('user', JSON.stringify(response.data.user))
  localStorage.setItem('token', response.data.key);
  return response.data
}

function handleError() {
  const errors = [
    "Te equivocaste en el usuario o en la contraseña. Es una pena porque si no te hubieses equivocado ahora estarías en el home. La próxima vez será :( ",
    "Hoy no volves a Boedo",
    "Seguí participando",
    "sudo apt-get install discomposer",
  ]
  const randomIndex = Math.floor((Math.random() * errors.length) + 1) % errors.length
  throw new Error(errors[randomIndex])
}