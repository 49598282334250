import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/routes/routes.js"
import { DarkThemeProvider } from "./context/ThemeContext.js";

function App() {
  return (
    <DarkThemeProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </DarkThemeProvider>
  )
}

export default App