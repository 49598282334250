import React from "react";
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { IconButton } from "@material-ui/core";
import { logout } from "../api/authentication";


const MainContent = (props) => {
  return <div style={{
    position: 'relative',
    overflow: 'hidden',
    transition: 'all .30s',
    marginLeft: props.expanded ? 240 : 64
  }}>
    {props.children}
  </div>
}

function Main(props) {

  const [state, setState] = React.useState({
    expanded: false
  })

  const history = useHistory()

  const onSelect = (selected) => {
    if (selected === 'stats')
      history.push('/home/statistics')
    else if (selected === 'home')
      history.push('/home/timeEntries')
    else if (selected === 'vacation')
      history.push('/home/vacation')
    else if (selected === 'general-vacations')
      history.push('/home/general-vacations')
  };

  const onToggle = (expanded) => {
    setState({ expanded: expanded });
  };

  function doLogout() {
    logout()
    history.replace('', { variant: 'success', message: 'Te haces la estrella pero ya vas a volver' })
  }

  return (
    <div className="page-content">
      <SideNav onSelect={onSelect} onToggle={onToggle} style={{ background: "rgb(64, 183, 89)", position: 'fixed' }}>
        <SideNav.Toggle />
        <SideNav.Nav selected={props.selected}>
          <NavItem eventKey="home">
            <NavIcon>
              <IconButton>
                <HomeIcon />
              </IconButton>
            </NavIcon>
            <NavText>
              Carga de horas
            </NavText>
          </NavItem>
          <NavItem eventKey="vacation">
            <NavIcon>
              <IconButton>
                <BeachAccessIcon />
              </IconButton>
            </NavIcon>
            <NavText>
              Vacaciones
            </NavText>
          </NavItem>
          <NavItem eventKey="stats">
            <NavIcon>
              <IconButton>
                <TimelineIcon />
              </IconButton>
            </NavIcon>
            <NavText>
              Estadísticas
            </NavText>
          </NavItem>
          <NavItem eventKey="logout">
            <NavIcon>
              <IconButton onClick={doLogout}>
                <ExitToAppIcon />
              </IconButton>
            </NavIcon>
            <NavText>
              Cerrar Sesión
            </NavText>
          </NavItem>
        </SideNav.Nav>

      </SideNav>
      <MainContent expanded={state.expanded} >
        <props.Component>
        </props.Component>
      </MainContent>
    </div>
  )
}

export default Main
