import { useTheme } from "@material-ui/core"

export default function VacationsCardItemContainer({ children }) {
  const theme = useTheme()
  return (
    <div style={{ display: 'flex', marginLeft: theme.spacing(3), marginTop: theme.spacing(1) }}>
      <div>
        {children}
      </div>
    </div>
  )
}