import React, { useState } from 'react';
import moment from 'moment';
import { GaiaDatePicker } from '../../toolbar/components/GaiaDatePicker';
import ItemSelect from './ItemSelect';
import { DescriptionTextField } from './DescriptionTextField';
import { HoursTextField } from './HoursTextField';
import { HoursTimePicker } from './HoursTimePicker';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { IconButton } from '@material-ui/core';
import ButtonWrapper from '../../../common/ButtonWrapper';

export function AddWorkingHourItem({
  index,
  projects,
  onProjectSelect,
  categories,
  onCategorySelect,
  onDescriptionSet,
  onHoursSet,
  onDateSelect,
  project,
  category,
  description,
  hours,
  dateWorked,
}) {
  const [displayRange, setDisplayRange] = useState(false)
  const [startHour, setStartHour] = useState(moment())
  const [endHour, setEndHour] = useState(moment())

  function calculateHourDiff(start, end) {
    //calcula la diferencia entre dos horarios. El true permite que la diferencia contenga decimales y toFixed indica la cantidad de los mismos
    const resultHours = end.diff(start, 'hours', true).toFixed(2)
    //Esto sirve para calcular el modulo en js con numeros negativos (https://dustinpfister.github.io/2017/09/02/js-whats-wrong-with-modulo/)
    return ((resultHours % 24) + 24) % 24;
}

function handleStartChange(hour) {
  const momentHour = moment(hour)
  setStartHour(momentHour)
  onHoursSet(calculateHourDiff(momentHour, endHour))
}

function handleEndChange(hour) {
  const momentHour = moment(hour)
  setEndHour(momentHour)
  onHoursSet(calculateHourDiff(startHour, momentHour))
}

return <>
  <ItemSelect
    label="Proyecto"
    value={project}
    elements={projects}
    editTable={onProjectSelect} />

  <ItemSelect
    label="Categoría"
    value={category}
    elements={categories}
    editTable={onCategorySelect} />

  <DescriptionTextField
    label="Descripción"
    value={description}
    editTable={onDescriptionSet}
    multiline
    placeholder="No hice mucho pero tampoco nada" />

  <ButtonWrapper>
    <IconButton
      onClick={() => setDisplayRange(!displayRange)}
      color={displayRange ? 'secondary' : ''}>
      <TimelapseIcon />
    </IconButton>
  </ButtonWrapper>

  {displayRange &&
    <>
      <HoursTimePicker
        label="Desde"
        value={startHour}
        onChange={handleStartChange}
      />

      <HoursTimePicker
        label="Hasta"
        value={endHour}
        onChange={handleEndChange}
      />
    </>}

  {!displayRange &&
    <HoursTextField
      label="Horas"
      value={hours}
      editTable={onHoursSet} />}

  <GaiaDatePicker
    label="Fecha"
    style={{ flexGrow: 1, width: '20%', margin: '17px' }}
    value={dateWorked}
    onChange={(date) => onDateSelect(moment(date))}
    dataCy={`DateRow${index}`}
  />
</>;
}
